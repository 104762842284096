.desktop-header {
  box-shadow: 0px 5px 14px -6px rgba(0, 0, 0, 0.15);
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  height: 100%;
  z-index: 100;
  background-color: #fff;
}

.desktop-header__separator {
  margin: 0;
  color: rgba(51, 51, 51, 0.2);
}

.desktop-header__links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 9px;
  align-self: self-start;
  padding: 13.5px 0;
}

.desktop-header__categories {
  flex: 1;
}
